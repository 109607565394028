@import "../../../../mixing.scss";

body[dir="rtl"] {

    .d-p-related-p-slider-container {
        margin-block-start: 2rem;
        .slick-list {
            margin-inline: 5%;
        }
        .slick-slide {
            padding-inline: 1rem;
        }
        .slick-next {
            inset-inline-end: unset;
            inset-inline-start: 0;
            transform: rotate(180deg) translateY(50%);
        }
        .slick-prev {
            inset-inline-start: unset;
            inset-inline-end: 0;
            transform: rotate(180deg) translateY(50%);
        }
    }
}

.d-p-related-p-slider-container {
    margin-block-start: 2rem;
    .slick-list {
        margin-inline: 5%;
    }
    .slick-slide {
        padding-inline: 1rem;
    }
    .slick-next {
        inset-inline-end: 0;
    }
    .slick-prev {
        inset-inline-start: 0;
    }
    
    .slick-next,
    .slick-prev {
        z-index: 1;
        height: calc(3rem * var(--global-text-size, 1));
        width: calc(3rem * var(--global-text-size, 1));
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--gray-06);
        border-radius: 50%;
        transition: .1s ease-in-out;
        &::before {
            content: "";
        }
        > span {
            color: var(--gray-02);
            font-size: calc(2rem * var(--global-text-size, 1));
        }
        @include desktopHover() {
            background: var(--gray-04);
        }
        &:active {
            background: var(--gray-06);
        }
    }

    .d-p-placeholder-slide {
        width: 100%;
        aspect-ratio: 3 / 4;
        border-radius: 1rem;
        overflow: hidden;
        background: var(--gray-01);
        border: 2px solid var(--gray-03);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .5;
    }
}