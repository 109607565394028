.p-share-popup-options-container {
    width: 100%;
    height: fit-content;
    min-height: 10rem;
    display: flex;
    gap: 2rem;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar-thumb {
        background: var(--gray-04);
        border-radius: 4px;
      }
    &::-webkit-scrollbar {
        // height: 4px;
    }

}