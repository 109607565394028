body.mobile {
    .p-title-container {
        width: fit-content;
        > h2 {
            font-size: calc(2.2rem * var(--global-text-size, 1));
        }
    }
}

.p-title-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    > h2 {
        color: var(--gray-06);
        text-transform: capitalize;
    }
}