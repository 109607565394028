.hero-section {
    position: relative;
    width: 100%;
    height: calc(100vh - 10rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 5rem;
    background: var(--gray-08);
    img.skelaton {
        max-height: 600px;
        max-width: 100%;
    }
}