body.mobile {
    .contact-title-container {
        margin-block-start: 4rem;
    }
}

.contact-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-block-start: 5rem;
    margin-block-end: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
        text-align: center;
        margin: 0;
    }
}