@import "../../../mixing.scss";

body.mobile {
    a.p-go-to-cart-container {
        &.active {
            height: 0;
            margin-block-end: 0;
            pointer-events: none;
            background: var(--transparent);
            transition: .2s ease-in-out;
            > p {
                color: var(--transparent);
            }
        }
        &.active-on-mobil {
            margin-block-end: 1rem;
            height: 4rem;
            pointer-events: initial;
            background: var(--gray-08);
            transition: .1s ease-in-out;
            > p {
                color: var(--gray-02);
            }
        }
    }
}

a.p-go-to-cart-container {
    width: 100%;
    background: var(--transparent);
    border-radius: 3px;
    margin-block-start: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: relative;
    transition: .2s ease-in-out;
    cursor: pointer;
    font-weight: 500;
    pointer-events: none;
    user-select: none;
    height: 0;
    &.active {
        height: 4rem;
        pointer-events: initial;
        background: var(--gray-08);
        transition: .1s ease-in-out;
        > p {
            color: var(--gray-02);
        }
        @include desktopHover() {
            background: var(--gray-06);
        }
        &:active {
            color: var(--gray-08);
        }
    }
    > p {
        color: var(--transparent);
        margin-inline-start: .5rem;
        transition: .2s ease-in-out;
        display: flex;
        align-items: center;
    }
}