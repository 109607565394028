@import "../../../../../mixing.scss";

@keyframes expandWidth {
    from {
      width: 0;
      min-width: 0;
    }
    to {
      width: 100%;
      min-width: 100%;
    }
}

@keyframes opacityIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

body[dir="rtl"] {
    .d-s-filters-dropdown-container {
        button.d-s-filters-dropdown-top {
            > p {
                &::after {
                    transform: rotate(225deg);
                }
                &.open {
                    &::after {
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
}

.d-s-filters-dropdown-container {
    min-width: 100%;
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    button.d-s-filters-dropdown-top {
        background: unset;
        border: none;
        margin-block-start: 2rem;
        width: fit-content;
        min-width: 100%;
        overflow: hidden;
        height: 5rem;
        display: flex;
        align-items: center;
        padding: 2rem;
        position: relative;
        animation: expandWidth .4s ease-out !important;
        cursor: pointer;
        @include desktopHover() {
            > p {
                color: var(--gray-05);
                &::after {
                    border-inline-start-color: var(--gray-05);
                    border-block-end-color: var(--gray-05);
                }
            }
        }
        &::before {
            content: "";
            width: 2px;
            border-radius: 2px;
            background: var(--gray-06);
            position: absolute;
            inset-block: 0;
            inset-inline-start: 0;
            transition: .2s ease-in-out !important;
        }
        &::after {
            content: "";
            width: 2px;
            border-radius: 2px;
            background: var(--gray-06);
            position: absolute;
            inset-block: 0;
            inset-inline-end: 0;
            transition: .2s ease-in-out !important;
        }
        > p {
            font-size: calc(1.8rem * var(--global-text-size, 1));
            display: flex;
            align-items: center;
            position: relative;
            color: var(--gray-06);
            font-weight: 500;
            width: 100%;
            text-transform: capitalize;
            white-space: nowrap;
            animation: opacityIn .4s ease-in-out;
            transition: .2s ease-in-out;
            &::after {
                content: "";
                width: calc(7px * var(--global-text-size, 1));
                height: calc(7px * var(--global-text-size, 1));
                border-inline-start: 2px solid var(--gray-06);
                border-block-end: 2px solid var(--gray-06);
                border-width: calc(2px * var(--global-text-size, 1));
                position: absolute;
                inset-inline-end: 0;
                transform: rotate(135deg);
                transition: .2s ease-in-out;
            }
            &.open {
                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
    ul {
        padding: 0;
        width: 100%;
        inset-block-start: 7rem;
        inset-inline: 0;
        inset-block-end: 0;
        max-height: 0;
        position: absolute;
        z-index: 1;
        transition: .2s ease-in-out;
        overflow: hidden;
        background: var(--gray-01);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-block-start: 1rem;
        border-radius: 3px;
        padding-inline: 2rem;
        &.open {
            max-height: 100vh;
        }
    }
}