body[dir="rtl"] {
    a.m-h-cart-container {
        > span {
            &.flip-on-mobile {
                transform: scaleX(-1)
            }
        }
    }    
}

a.m-h-cart-container {
    height: 4rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    opacity: 0;
    transition: .2s ease-in-out;
    pointer-events: none;
    text-decoration: none;
    color: var(--gray-02);
    margin-inline: auto calc(2rem + 1vw);
    &.active {
        pointer-events: initial;
        opacity: 1;
    }
    > span {
        font-size: calc(2.4rem * var(--global-text-size, 1));
        &.flip-on-mobile {
            transition: transform .4s ease;
        }
    }
    .visually-hidden {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        clip-path: inset(50%);
    }
}