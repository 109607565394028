.e-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-inline: 5rem;
    > .e-form-inner-container {
        width: 100%;
        max-width: 1600px;
        display: flex;
        align-items: center;
        justify-content: center;
        > form {
            width: 100%;
            min-height: calc(100vh - 10rem);
            display: flex;
            gap: 5rem;
            padding-block: 5rem;
        }
    }
    &.mobile {
        padding-inline: 5%;
        > .e-form-inner-container {
            display: flex;
            flex-direction: column;
            > form {
                min-height: 80vh;
                gap: 2rem;
                padding-block: 2rem;
                flex-direction: column-reverse;
                justify-content: flex-end;
            }
        }
    }
}
