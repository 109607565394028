@import "../../../../../mixing.scss";

body[dir="rtl"] {
    button.d-p-back {
        > span {
            margin-inline-end: unset;
            margin-inline-start: 1rem;
            transform: scaleX(-1);
            
        }
    }
}

button.d-p-back {
    width: fit-content;
    display: flex;
    align-items: center;
    color: var(--gray-06);
    font-weight: 500;
    transition: .1s ease-in-out;
    border: none;
    background: none;
    cursor: pointer;
    font-size: calc(2rem * var(--global-text-size, 1));
    user-select: none;
    > span {
        margin-inline-end: 1rem;
        font-size: calc(2.4rem * var(--global-text-size, 1));
    }
    @include desktopHover() {
        color: var(--gray-08);
    }
    &:active {
        color: var(--gray-06);
    }
}