.c-sum-subtotal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-block-end: 1rem;
    border-block-end: 1px solid var(--gray-02);
    > p {
        color: var(--gray-06);
        margin-block-end: .5rem;
        text-decoration: underline;
    }
    .c-sum-subtotal-inner-container {
        display: flex;
        flex-wrap: wrap;
        > h2 {
            color: var(--gray-06);
            width: fit-content;
        }
    }
}