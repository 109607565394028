.d-p-thumbnails-container {
    height: 100%;
    overflow-y: scroll;
    min-width: 60px;
    &::-webkit-scrollbar {
        display: none; /* Hide scrollbar in WebKit browsers */
    }
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
    .d-p-thumbnails-inner-container {
        width: 100%;
        max-width: 60px;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        height: fit-content;
    }
}