section.m-terms {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 5rem);
    padding-block-start: 10rem;
    > h3 {
        margin-block-end: 2rem;
    }
    .m-terms-inner-container {
    }
}