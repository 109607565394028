.remove-notify-available-success-message-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > h2 {
        color: var(--gray-02);
        margin-block-end: 2rem;
        text-align: center;
    }
    > p {
        color: var(--gray-04);
        text-align: center;
        padding-inline: 1rem;
        > a {
            color: var(--gray-02);
        }
    }
}