header {
    position: fixed;
    width: 100%;
    z-index: 1000;
    inset-block-start: 0;
    background: var(--gray-08);
    display: flex;
    justify-content: center;
    transition: .2s ease-out;
    &.scroll-up {
        inset-block-start: 0;
    }
    &.scroll-down {
        inset-block-start: -100px;
    }
}