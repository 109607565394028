@import "../../../../mixing.scss";

body.mobile {
    .e-form-submit-container {
        margin-inline: auto;
        margin-block-end: 6rem;
        margin-block-start: 2rem
    }    
}

.e-form-submit-container {
    width: 200px;
    height: 5rem;
    margin-block-start: auto;
    margin-inline-start: auto;
    cursor: pointer;
    input.submit {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--gray-08);
        border-radius: 3px;
        transition: .1s ease-in-out;
        color: var(--gray-02);
        font-size: calc(1.6rem * var(--global-text-size, 1));
        border: none;
        cursor: pointer;
        &:focus {
            outline-color: transparent;
        }
        @include desktopHover() {
            background: var(--gray-06);
        }
        &:active {
            background: var(--gray-08);
        }
        &::placeholder {
            transition: .2s ease-in-out;
            color: var(--gray-04);
        }
        &.error {
            outline-color: var(--gray-04);
            &::placeholder {
                color: var(--blue-01);
            }
        }
    }
}