.contact-email-container {
    width: 100%;
    input.email {
        font-family: 'Poppins', sans-serif;
        width: 100%;
        height: 5rem;
        margin-block-start: 2rem;
        margin-inline-end: auto;
        font-size: calc(1.6rem * var(--global-text-size, 1));
        border: 2px solid transparent;
        padding: 1rem;
        background-color: var(--gray-01);
        border-radius: 3px;
        transition: .2s ease-in-out;
        opacity: 1;
        text-align: left;
        direction: ltr;
        &:dir(rtl) {
            text-align: left;
        }
        &:focus {
            border-color: var(--gray-06);
            outline: none;
        }
        &::placeholder {
            transition: .2s ease-in-out;
            color: var(--gray-04);
        }
        &.error {
            border-color: var(--gray-04);
            &::placeholder {
                color: var(--blue-01);
            }
        }
    }
}