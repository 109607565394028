body.mobile {
    .lang-popup-container {
        padding-inline: 5%;
        .lang-popup-inner-container {
            height: fit-content;
            max-height: unset;
        }
    }
}

.lang-popup-container {
    position: fixed;
    inset: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn .2s ease-in;
    direction: ltr;
    .lang-popup-inner-container {
        width: 100%;
        max-width: 40rem;
        border-radius: 5px;
        overflow: hidden;
        background: var(--gray-01);
        border: 2px solid var(--gray-04);
        position: relative;
        z-index: 2;
    }
    .lang-popup-cover {
        position: absolute;
        z-index: 1;
        inset: 0;
        background: var(--gray-01-opacity);
    }
}