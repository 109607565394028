.cover-layer {
    position: absolute;
    inset: 0;
    &.loading,
    &.error {
        background: var(--gray-08-opacity);
    }
    &.success {
        background: var(--gray-02-opacity);
    }
}