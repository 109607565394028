@import '_mixing';
@import '_variables';
@import '_typography';
@import '_keyframes';

* {
    box-sizing: border-box;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    #root {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background: var(--gray-08);
    }
}

body * {

}

body.custom-line-height *:not(.accessibility-container, .accessibility-container *) {
    line-height: var(--custom-line-height, 1) !important;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-block-start: 10rem;
    min-height: 100vh;
    width: 100%;
    animation: fadeIn .2s ease-in;
    transition: .2s ease-in-out;
}

.grecaptcha-badge { 
    visibility: hidden !important;
}

body.mobile {
    .page {
        padding-block-start: 5rem;
    } 
}

body.big-cursor-active {
    cursor: url("../assets/big-cursor.svg"), auto;
    button {
        cursor: url("../assets/big-cursor.svg"), auto;
    }
}

body.underline-links-active a {
    text-decoration: underline !important;
}