body.mobile {
    .terms {
        padding-inline: 5%;
        padding-block-end: 0;
    }
}

.terms {
    padding-block-start: 5rem;
    padding-block-end: 5rem;
    width: 100%;
    // max-width: 1600px;
    display: flex;
    justify-content: space-between;
    padding-inline: 5rem;
    background: var(--gray-02);
}