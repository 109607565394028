@import "../../../../../../mixing.scss";

button.d-p-t-button-up-contaienr {
    width: 100%;
    max-width: 60px;
    aspect-ratio: 1 / 1;
    background: linear-gradient(to top, var(--transparent), var(--gray-02));
    position: absolute;
    z-index: 1;
    inset-block-start: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border: none;
    &.active {
        opacity: 1;
        visibility: visible;
    }
    > span {
        transform: translateY(-50%);
        padding: .5rem;
        color: var(--gray-02);
        font-size: calc(2.4rem * var(--global-text-size, 1));
        background: var(--gray-06);
        border: 2px solid var(--gray-02);
        border-radius: 50%;
        transition: .1s ease-in-out;
        box-shadow: 0 0 10px var(--gray-02);
        user-select: none;
        @include desktopHover() {
            cursor: pointer;
            background: var(--gray-05);
        }
        &:active {
            background: var(--gray-06);
        }
    }
}