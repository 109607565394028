@import "../../../../../mixing.scss";

li.m-faq-cat-item-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-block-end: 1px solid var(--gray-03);
    border-radius: 3px;
    transition: .2s ease-in-out;
    position: relative;
    user-select: none;
    cursor: pointer;
    &.selected {
        background: var(--gray-08);
        > a {
            color: var(--gray-04);
        }
    }
    > a {
        color: var(--gray-08);
        text-transform: capitalize;
        font-weight: 500;
        transition: .1s ease-in-out;
        font-size: calc(2rem * var(--global-text-size, 1));
        padding-block: 2rem;
        user-select: none;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }
}