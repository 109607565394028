.d-p-t-v-cover-container {
    position: absolute;
    inset: 0;
    background: var(--gray-06-opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
        color: var(--gray-02);
        font-size: 2.4rem;
    }
}