@import "../../../mixing.scss";

.c-placeholder-container {
    padding-block: 4rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > span {
        font-size: calc(5rem * var(--global-text-size, 1));
        width: 5rem;
        color: var(--gray-06);
    }
    > h2 {
        color: var(--gray-06);
        margin-block-start: 2rem;
    }
    > a {
        margin-block-start: 1rem;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--gray-06);
        transition: .1s ease-in-out;
        > span {
            margin-inline: .5rem;
            font-size: calc(2.4rem * var(--global-text-size, 1));
        }
        @include desktopHover() {
            color: var(--gray-04);
        }
        &:active {
            color: var(--gray-06);
        }
    }
}
