.p-success-container {
    display: none;
    position: absolute;
    inset: 0;
    transition: .1s ease-in-out;
    &.active {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--gray-02);
        z-index: 1;
    }
    > span {
        font-size: calc(4rem * var(--global-text-size, 1));
        color: var(--gray-06);
    }
}