@import "../../../../app/mixing.scss";

body.mobile {
    .language-switcher-container {
        margin-inline: auto calc(2rem + 1vw);
        p {
            color: var(--gray-08);
            font-weight: 600;
        }
        span {
            color: var(--gray-08);
        }
        @include desktopHover() {
            p {
                color: var(--gray-08);
            }
            span {
                color: var(--gray-08);
            }
        }
        &:active {
            p {
                color: var(--gray-04);
            }   
            span {
                color: var(--gray-04);
            }
        }
    }
}

body[dir="rtl"].mobile {
    .language-switcher-container {
        margin-inline: calc(2rem + 1vw) auto;
    }
}

button.language-switcher-container {
    margin-inline: calc(2rem + 1vw);
    direction: ltr;
    border: none;
    background: unset;
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    @include desktopHover() {
        p {
            color: var(--gray-02);
        }
        span {
            color: var(--gray-02);
        }
    }
    p {
        font-size: calc(1.6rem * var(--global-text-size, 1));
        color: var(--gray-04);
        text-transform: uppercase;
        line-height: 1;
        transition: .2s ease-in-out;
    }
    span {
        transition: .2s ease-in-out;
        color: var(--gray-04);
        font-size: calc(2.4rem * var(--global-text-size, 1));
    }
}