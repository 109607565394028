@import "../../../mixing.scss";

body.mobile {
    .c-item-data-container {
        width: calc(100% - 15rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem;
        .c-item-data-inner-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;
            max-width: 70%;
            margin-inline-end: 0;
            @include respond(500) {
                max-width: 100%;
            }
            > p {
                font-weight: 400;
                font-size: calc(1.4rem * var(--global-text-size, 1));
                white-space: wrap;
            }
        }
        > p {
            display: none;   
        }
    }
}

body[dir="rtl"] {
    .c-item-data-container {
        > p {
            > span {
                transform: scaleX(-1);
                margin-inline-end: unset;
                margin-inline-start: 1rem;
            }
        }
    }
}

.c-item-data-container {
    min-height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    > p {
        color: var(--gray-06);
        display: flex;
        align-items: center;
        transition: .1s ease-in-out;
        > span {
            margin-inline-end: 1rem;
            font-size: calc(2.4rem * var(--global-text-size, 1));
        }
    }
    @include desktopHover() {
        > p {
            color: var(--gray-04);
        }
        .c-item-data-inner-container {
            > p {
                color: var(--gray-04);
            }
        }
    }
    &:active {
        > p {
             color: var(--gray-06);
        }
        .c-item-data-inner-container {
            > p {
                color: var(--gray-06);
            }
        }
    }
    .c-item-data-inner-container {
        min-height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;
        cursor: pointer;
        > p {
            margin-inline-end: 4rem;
            color: var(--gray-06);
            font-weight: 500;
            transition: .1s ease-in-out;
            display: flex;
            align-items: center;
            white-space: nowrap;
            > span {
                margin-inline-end: 1rem;
            }
        }
    }
}