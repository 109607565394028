@import "../../../../../mixing.scss";

.p-n-abort-container {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    transition: .1s ease-in-out;
    padding-inline: 1rem;
    width: fit-content;
    > span {
        transition: .1s ease-in-out;
        color: var(--gray-08);
        font-size: calc(2.4rem* var(--global-text-size, 1));
    }
    @include desktopHover() {
        > span {
            color: var(--gray-06);
        }
    };
    &:active {
        > span {
            color: var(--gray-08);
        }
    }
}