header.desktop {
    position: fixed;
    inset-block-start: 0;
    height: 100px;
    padding-inline: 5rem;
    transition: .2s ease-in-out !important;
    &.scroll-up {
        inset-block-start: 0;
    }
    &.scroll-down {
        inset-block-start: -100px;
    }
    .inner-container {
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 1600px;
    }
}