body.mobile {
    .product {
        padding-inline: 0;
    }    
}

.product {
    background: var(--gray-02);
    padding-inline: 5rem;
    min-height: calc(100vh - 10rem);
}