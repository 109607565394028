@import "../../../mixing.scss";

.p-preview-container {
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    .p-preview-inner-container {
        transition: .1s ease-in-out;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        opacity: 1;
        background: var(--gray-01);
        .p-preview-bottom-container {
            width: 100%;
            padding-block: 1rem;
            display: flex;
            justify-content: center;
            border-block-start: 2px solid var(--gray-02);
        }
    }
    @include desktopHover() {
        .d-s-p-preview-inner-container {
            opacity: .5;
        }
        &:active {
            .d-s-p-preview-inner-container {
                opacity: 1;
            }   
        }
    }
}