body.mobile {
    .page.skateshop {
        padding-inline: 5%;
        padding-block-start: 15rem;
        padding-block-end: 4rem;
    }
}

.skateshop {
    background: var(--gray-02);
    padding-inline: 5rem;
}