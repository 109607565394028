@import "../../../../../mixing.scss";

body.mobile {
    ul.p-p-c-c-list {
        > li {
            padding-block: 1rem;
            > p {
                font-size: calc(1.4rem * var(--global-text-size, 1));
            }
        }
    }    
}

ul.p-p-c-c-list {
    width: 100%;
    height: fit-content;
    overflow: hidden;
    max-height: 0;
    padding-inline: 0;
    list-style-type: none;
    transition: .3s ease-in-out;
    &.open {
        max-height: 200px;
    }
    > li {
        background: var(--gray-08);
        padding-block: .5rem;
        padding-inline: 1rem;
        cursor: pointer;
        border: 1px solid var(--gray-02);
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        direction: ltr;
        > p {
            transition: .1s ease-in-out;
            font-size: calc(1.4rem * var(--global-text-size, 1));
            color: var(--gray-02);
        }
        @include desktopHover() {
            > p {
                color: var(--gray-03);
            }
        }
        &:active {
            > p {
                color: var(--gray-02);
            }
        }
    }
}