section.d-p-main-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    .d-p-inner-main-container {
        width: 100%;
        min-height: calc(100vh - 10rem);
        padding-block-end: 10rem;
        display: flex;
        position: relative;
        gap: 5rem;
    }
}