.loading-container {
    position: absolute;
    z-index: 1;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &.light {
        background: var(--gray-02);
        &:before {
            content: "";
            border-color: var(--gray-04);
            border-top-color: var(--gray-03);
        }
    }
    &.dark {
        background: var(--gray-08);
        &:before {
            content: "";
            border-color: var(--gray-07);
            border-top-color: var(--gray-06);
        }
    }
    &:before {
        content: "";
        border: 10px solid;
        border-top: 10px solid;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 1s linear infinite;
    }
}