.c-sum-tax-container {
    width: 100%;
    margin-block-start: 2rem;
    > p {
        font-size: calc(1.6rem * var(--global-text-size, 1));
        color: var(--gray-06);
        margin-block-end: .5rem;
        &:first-child {
            text-decoration: underline;
        }
    }
}