body.mobile {
    .faq-list-container {
        margin-block-start: 5rem;
        padding-inline: 5%;
    }    
}

.faq-list-container {
    width: 100%;
    margin-block-start: 5rem;
    > ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}