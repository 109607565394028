body.mobile {
    .dialog-message-container {
        width: 90%;
        max-width: 100%;
        height: fit-content;
        min-height: unset;
        max-height: unset;
        transition: .2s ease-in-out;
    }
}

.dialog-message-container {
    position: absolute;
    z-index: 1000;
    width: 100%;
    max-width: 60rem;
    height: 100%;
    max-height: 40rem;
    border-radius: 5px;
    overflow: hidden;
    transition: .2s ease-in-out;
    &.error {
        background: var(--gray-02);        
    }
    &.success {
        background: var(--gray-08);
    }
}