:root {

    --gray-09: rgba(0, 0, 0, 1);
    --gray-08: rgba(20, 20, 20, 1);
    --gray-07: rgba(40, 40, 40, 1);
    --gray-06: rgba(80, 80, 80, 1);
    --gray-05: rgba(120, 120, 120, 1);
    --gray-04: rgba(160, 160, 160, 1);
    --gray-03: rgba(200, 200, 200, 1);
    --gray-02: rgba(240, 240, 240, 1);
    --gray-01: rgba(255, 255, 255, 1);

    --gray-09-opacity: rgba(0, 0, 0, .8);
    --gray-08-opacity: rgba(20, 20, 20, .8);
    --gray-07-opacity: rgba(40, 40, 40, .8);
    --gray-06-opacity: rgba(80, 80, 80, .8);
    --gray-05-opacity: rgba(120, 120, 120, .8);
    --gray-04-opacity: rgba(160, 160, 160, .8);
    --gray-03-opacity: rgba(200, 200, 200, .8);
    --gray-02-opacity: rgba(240, 240, 240, .8);
    --gray-01-opacity: rgba(255, 255, 255, .8);

    --transparent: rgba(0, 0, 0, 0);

    --pink-01: rgba(233, 87, 150, 1);
    --red-01: rgba(192, 61, 41, 1);
    --green-01: rgba(120, 177, 63, 1);
    --blue-01: rgba(52, 152, 219, 1);

}