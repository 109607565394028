@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Material Icons Outline';
    font-style: normal;
    font-weight: 400;
    src: url('../../public/fonts/material-icons/MaterialIconsOutlined-Regular.otf') format('truetype');
}

html {
    body {
        h1, h2, h3, h4, p, a, li {
            font-family: 'Poppins', sans-serif;
            color: var(--gray-06);
            font-weight: 400;
            margin: 0;
            line-height: 1.3;
        }
        h1 {
            font-size: calc(4rem * var(--global-text-size, 1));
        }
        h2 {
            font-size: calc(3.2rem * var(--global-text-size, 1));
        }
        h3 {
            font-size: calc(2.4rem * var(--global-text-size, 1));
        }
        h4 {
            font-size: calc(2.2rem * var(--global-text-size, 1));
        }
        p {
            font-size: calc(1.6rem * var(--global-text-size, 1));
        }
        a {
            font-size: calc(1.6rem * var(--global-text-size, 1));
        }
        li {
            font-size: calc(1.6rem * var(--global-text-size, 1));
        }
        &.mobile {
            h1 {
                font-size: calc(3.8rem * var(--global-text-size, 1));
            }
            h2 {
                font-size: calc(3rem * var(--global-text-size, 1));
            }
            h3 {
                font-size: calc(2.2rem * var(--global-text-size, 1));
            }
            h4 {
                font-size: calc(2rem * var(--global-text-size, 1));

            }
            p {
                font-size: calc(1.8rem * var(--global-text-size, 1));
            }
            a {
                font-size: calc(1.8rem * var(--global-text-size, 1));
            }
            li {
                font-size: calc(1.6rem * var(--global-text-size, 1));
            }
        }
        .material-symbols-outlined {
            font-family: 'Material Icons Outline';
            font-weight: normal;
            font-style: normal;
            display: inline-block;
            line-height: 1;
            text-transform: none;
            letter-spacing: normal;
            word-wrap: normal;
            white-space: nowrap;
            direction: ltr;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
}
