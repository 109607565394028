@import "../../../../../mixing.scss";

button.d-p-releted-slide-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: none;
    background: none;
    width: 100%;
    @include desktopHover() {
        .d-p-releted-slide-img-container {
            border: 2px solid var(--gray-06);    
        }        
    }
    .d-p-releted-slide-img-container {
        width: 100%;
        aspect-ratio: 3 / 4;
        border-radius: 1rem;
        overflow: hidden;
        background: var(--gray-01);
        border: 2px solid var(--gray-04);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .1s ease-in-out;
    }
    .d-p-releted-slide-bottom-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-block-start: .5rem;
        padding-inline: .5rem;
        > p {
            text-align: center;
            font-size: calc(1.4rem * var(--global-text-size, 1));
        }
    }
}