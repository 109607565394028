.q-container {
    width: 100%;
    height: 100%;
    min-height: 5rem;
    background: var(--gray-02);
    display: flex;
    align-items: center;
    padding: 1rem;
    > p {
        color: var(--gray-06);
        font-weight: 400;
    }
}