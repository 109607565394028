.m-p-releted-p-slider-container {
    padding: 0 5%;
    overflow: hidden;
    .slick-list {
        overflow: visible !important;
    }
    .slick-slide {
        padding-inline-end: 2rem;
    }
    
    .m-p-placeholder-slide {
        width: 100%;
        aspect-ratio: 3 / 4;
        border-radius: 1rem;
        overflow: hidden;
        background: var(--gray-01);
        border: 2px solid var(--gray-04);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .2;
    }
    
}