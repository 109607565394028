section.m-p-releted-p-section {
    width: 100%;
    margin-block-end: 4rem;
    margin-block-start: 2rem;
    .m-p-releted-p-header {
        width: 100%;
        padding-inline: 5%;
        margin-block-end: 1rem;
        > h2 {
            color: var(--gray-06);
            font-size: calc(2.2rem * var(--global-text-size, 1));
        }
    }
}