.exchange-success-message-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > h2 {
        color: var(--gray-04);
        margin-block-end: 2rem;
        text-align: center;
    }
    > p {
        color: var(--gray-04);
        text-align: center;
    }
}