.m-p-gallery-container {
    width: 100%;
    position: relative;
}

// body[dir="rtl"] {
//     .m-p-gallery-container {
//         .slick-slider {
//             direction: ltr;
//             .slick-list {
//                 direction: ltr;
//                 .slick-track {
//                     direction: ltr;
//                 }
//             }
//         }
//     }
// }

ul.slick-dots {
    display: flex !important;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    inset-block-start: 100%;
    gap: 2px;
    height: fit-content;
    li {
        display: flex;
        justify-content: center;
        margin: 0;
        width: calc((100% / var(--number-of-dots)) - 1.5rem);
        height: fit-content;
        transition: 0.5s ease-in-out;
        background: var(--gray-04);
        button {
            cursor: pointer;
            height: .5rem;
            width: 100%;
            border: none;
            padding: 0;
            &::before {
                content: "";
            }
        }
        &.slick-active {
            width: calc((100% / var(--number-of-dots)) + (var(--number-of-dots) * 1.5rem));
            background: var(--gray-06);
        }
    }
}