@import "../../../../../../../mixing.scss";

body.mobile {
    button.p-option-value-container {
        height: fit-content;
        min-height: 3rem;
        padding-inline: 1.5rem;
        > p {
            font-size: calc(1.4rem * var(--global-text-size, 1));
         }
    }    
}

button.p-option-value-container {
    min-height: 3rem;
    height: fit-content;
    padding-inline: 2rem;
    background: var(--gray-03);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .1s ease-in-out;
    position: relative;
    border: none;
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        border: 2px solid var(--transparent);
        border-radius: 5px;
        transition: .2s ease-in-out;
    }
    &.active {
        background: var(--gray-05);
        @include desktopHover() {
            background: var(--gray-08);
        }
    }
    &.selected {
        background: var(--gray-08);
        &::before {
            inset: -3px;
            border-color: var(--gray-05);
        }
    }
    > p {
        font-size: calc(1.4rem * var(--global-text-size, 1));
        color: var(--gray-02);
        text-transform: capitalize;
    }
}