body.mobile {
    .p-share-popup-option-container {
        max-width: 100%;
        > p {
            font-size: calc(1.2rem * var(--global-text-size, 1)) !important;
            margin-block-start: 1rem;
        }
    }
}
.p-share-popup-option-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 6rem;
    cursor: pointer;
    > p {
        font-size: calc(1.4rem * var(--global-text-size, 1)) !important;
        margin-block-start: 1rem;
        text-transform: capitalize;
        white-space: nowrap;
    }
}