.m-p-video-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-08);
    overflow: hidden;
    > video {
        height: 100%;
        object-fit: cover;
    }
}