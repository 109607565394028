.m-p-sticky-container {
    width: 100%;
    padding-inline: 5%;
    position: sticky;
    inset-block-end: 0;
    inset-inline: 0;
    margin-block-start: auto;
    padding-block: 2rem;
    background: var(--gray-02);
    border-block-start: 1px solid var(--gray-03);
}