body[dir="rtl"] {
    nav.side-menu {
        ul {
            li {
                a {
                    span.material-symbols-outlined {
                        margin-inline-end: unset;
                        margin-inline-start: 1rem;
                        &.flip-on-rtl {
                            transform: scaleX(-1);
                        }
                    }
                }
            }
        }
    }    
}

nav.side-menu {
    ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding-inline: 5%;
        padding-block-start: 2rem;
        li {
            a {
                text-decoration: none;
                color: var(--gray-08);
                padding-block: 2rem;
                font-size: calc(2rem * var(--global-text-size, 1));
                font-weight: 500;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                transition: .2s ease-in-out;
                -webkit-tap-highlight-color: transparent;
                span.material-symbols-outlined {
                    margin-inline-end: 1rem;
                    color: var(--gray-08);
                    line-height: 1;
                    transition: .2s ease-in-out;
                    font-size: calc(2.4rem * var(--global-text-size, 1));
                }
            }
            &.active {
                a {
                    color: var(--gray-04);
                    span.material-symbols-outlined {
                        color: var(--gray-04);
                        &.flip-on-rtl {
                            transition: transform .4 ease;
                        }
                    }
                }
            }
        }
    }
}