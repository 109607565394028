@import "../../../mixing.scss";

body.mobile {
    .p-share-container {
        position: absolute;
        z-index: 1;
        margin: 0;
        background: var(--gray-01);
        border-radius: 3px;
        width: 4rem;
        height: 4rem;
        justify-content: center;
        inset-inline-end: 5%;
        inset-block-end: 2rem;
    }
}

.p-share-container {
    position: relative;
    padding-block: 1rem;
    margin-inline-start: 2rem !important;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-inline-start: auto;
    cursor: pointer;
    @include desktopHover() {
        > span {
            color: var(--gray-04);
        }
    }
    > span {
        color: var(--gray-06);
        transition: .1s ease-in-out;
        font-size: calc(2.4rem * var(--global-text-size, 1));
    }
    &:active {
        color: var(--gray-06);
    }
}