.l-i-title-container {
    > p {
        font-size: calc(1.6rem * var(--global-text-size, 1));
        font-weight: 600;
        color: var(--gray-04);
        transition: .1s ease-in-out;
    }
    &.active {
        > p {
            color: var(--gray-06);
        }
    }
}