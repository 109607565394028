@import "../../../../../app/mixing.scss";

body[dir="rtl"] {
    nav.desktop-footer-top-center-container {
        > ul {
            li {
                .inner-li-container {
                    a {
                        span.material-symbols-outlined {
                            margin-inline-end: unset;
                            margin-inline-start: 1rem;
                            &.flip-on-rtl {
                                transform: scaleX(-1);
                            }
                        }
                    }
                }
            }
        }
    }
}

nav.desktop-footer-top-center-container {
    margin-inline: calc(2rem + 1vw);
    > ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding: 0;
        li {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-inline: 0 calc(1rem + 4vw);
            p {
                margin-block-end: 2rem;
                color: var(--gray-02);
                user-select: none;
            }
            .inner-li-container {
                margin-block-end: 1rem;
                min-height: 2.5rem;
                display: flex;
                align-items: center;
                a {
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    transition: .2s ease-in-out;
                    color: var(--gray-04);
                    span.material-symbols-outlined {
                        margin-inline-end: 1rem;
                        font-size: calc(2.4rem * var(--global-text-size, 1));
                        &.flip-on-rtl {
                            transition: transform .4s ease;
                        }
                    }
                }
                @include desktopHover() {
                    a {
                        color: var(--gray-02);
                    }
                }
                &.active {
                    a {
                        color: var(--gray-02);
                    }
                }
            }
        }
    }
}