body.mobile {
    .c-item-img-container {
        width: 5rem;
        min-width: 5rem;
    }
}

.c-item-img-container {
    width: 8rem;
    min-width: 8rem;
    aspect-ratio: 3 / 4;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    > img {
        max-height: 100%;
        object-fit: cover;
    }
}