.c-s-s-change-currency-container {
    width: 65px;
    margin-inline-start: auto;
    position: relative;
    .c-s-s-change-currency-container {
        position: absolute;
        z-index: 1;
        inset-inline: 0;
        height: 100%;
        min-height: fit-content;
        display: flex;
        flex-direction: column;
    }
}