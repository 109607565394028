body.mobile {
    .e-form-t-counter-container {
        > p {
            font-size: calc(1.6rem * var(--global-text-size, 1));
        }
    }
}

.e-form-t-counter-container {
    height: 2rem;
    position: absolute;
    inset-block-start: 100%;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: .2s ease-in-out;
    padding-inline: 1rem;
    &.dir-rtl {
        inset-inline-start: 1rem;
    }
    &.dir-ltr {
        inset-inline-end: 1rem;
    }
    &.error {
        opacity: 1;
        > p {
            color: var(--blue-01);
        }
    }
    &.visible {
        opacity: 1;
    }
    > p {
        font-size: calc(1.2rem * var(--global-text-size, 1));
        margin: 0;
        color: var(--gray-03);
        transition: .2s ease-in-out;
    }
}