body.mobile {
    .faq {
        padding-inline: 0;
        padding-block-end: 4rem;
    }    
}

.faq {
    background: var(--gray-02);
    padding-inline: 5rem;
    padding-block-end: 5rem;
    .faq-inner-container {
        width: 100%;
        max-width: 1600px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}