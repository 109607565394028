body.mobile {
    .c-items-container {
        .c-items-inner-container {
            overflow-y: unset;
        }
    }   
}

.c-items-container {
    width: 100%;
    height: 100%;
    position: relative;
    .c-items-inner-container {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
    }
}