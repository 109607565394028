.faq-buttom-container {
    height: fit-content;
    max-height: 0;
    transition: .4s ease-in-out;
    overflow: hidden;
    padding-inline-start: 5.5rem;
    > p {
        padding-block-start: 1rem;
        margin-block-end: 2rem;
        color: var(--gray-06);
        font-weight: 400;
        border-block-start: 2px solid var(--gray-06);
        > span {
            &#sugarClick,
            &#contactClick {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    &.open {
        max-height: 50vh;
    }
}