.d-shop-container {
    padding-block-start: 5rem;
    padding-block-end: 10rem;
    width: 100%;
    max-width: 1600px;
    display: flex;
    justify-content: space-between;
    .d-shop-inner-container.one {
        min-width: 250px;
        margin-inline-end: 2rem;
    }
    .d-shop-inner-container.two {
        width: 100%;
    }
  }
  