.d-p-thombnail-container {
    width: 100%;
    aspect-ratio: 3 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-01);
    border-radius: 10px;
    border: 2px solid var(--gray-01);
    overflow: hidden;
    cursor: pointer;
    transition: .1s ease-in-out;
    &.active {
        border: 2px solid var(--gray-04);
    }
    &:hover {
        border: 2px solid var(--gray-04);
        &.active {
            border: 2px solid var(--gray-04);
        }
    }
}