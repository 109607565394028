.d-p-media-container {
    height: 100%;
    max-height: inherit;
    width: 100%;
    aspect-ratio: 3 / 4;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px;
    // background: var(--gray-01);
    background: var(--transparent);
    transition: opacity 300ms ease-in-out;
    &.fade {
      opacity: 0;
    }
}