@import "../../../mixing.scss";

body[dir="rtl"] {
    .c-checkout-container {
        > span {
            transform: scaleX(-1);
        }
    }
}

button.c-checkout-container {
    border: none;
    width: 100%;
    height: 6rem;
    background: var(--gray-08);
    margin-block-start: auto;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .1s ease-in-out;
    user-select: none;
    cursor: pointer;
    &.lock {
        background: var(--gray-03);
        pointer-events: none;
    }
    > p {
        color: var(--gray-02);
        margin-inline-end: 1rem;
    }
    > span {
        color: var(--gray-02);
        font-size: calc(2.4rem * var(--global-text-size, 1));
        transition: transform .4s ease;
    }
    @include desktopHover() {
        background: var(--gray-06);
    }
    &:active {
        background: var(--gray-08);
    }
}