body[dir="rtl"] {
    nav.mobile-footer-center-container {
        > ul {
            li {
                .inner-li-container {
                    a {
                        span.material-symbols-outlined {
                            margin-inline-end: unset;
                            margin-inline-start: 1rem;
                            &.flip-on-rtl {
                                transform: scaleX(-1);
                            }
                        }
                    }
                }
            }
        }
    }
}

nav.mobile-footer-center-container {
    width: 100%;
    > ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        li {
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-inline: 1rem;
            margin-block-start: 4rem;
            p {
                color: var(--gray-02);
                font-size: calc(1.3rem * var(--global-text-size, 1));
                margin-block-end: 2rem;
            }
            .inner-li-container {
                margin-block-end: 1rem;
                min-height: 2.5rem;
                display: flex;
                align-items: center;
                a {
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    font-size: calc(1.3rem * var(--global-text-size, 1));
                    color: var(--gray-04);
                    // span.material-symbols-outlined {
                    //     margin-inline-end: 1rem;
                    //     font-size: calc(2rem * var(--global-text-size, 1));
                    //     &.flip-on-rtl {
                    //         transition: transform .4s ease;
                    //     }
                    // }
                }
                &.active {
                    a {
                        color: var(--gray-02);
                    }
                }
            }
        }
    }
}