body.mobile {
    .cart {
        padding-inline: 0;
        .inner-cart-container {
            flex-direction: column;
            gap: unset;
            padding-block: 4rem 4rem;
            height: auto;
            position: relative;
            min-height: calc(100vh - 5rem);
        }
    }
}



.cart {
    background: var(--gray-02);
    padding-inline: 5rem;
    .inner-cart-container {
        width: 100%;
        max-width: 1600px;
        min-height: calc(100vh - 10rem);
        display: flex;
        gap: 5rem;
        padding-block: 5rem;
    }
}