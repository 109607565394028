@import "../../../mixing.scss";

.p-quantity-container {
    height: 4rem;
    display: flex;
    align-items: center;
    user-select: none;
    > p {
        margin-inline-end: 1rem;
        color: var(-06);
        font-weight: 500;
    }
    .p-quantity-inner-container {
        display: flex;
        align-items: center;
        border-radius: 3px;
        overflow: hidden;
        .quantity-button {
            width: 2.4rem;
            height: 2.4rem;
            background: var(--gray-06);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 50%;
            transition: .1s ease-in-out;
            @include desktopHover() {
                background: var(--gray-05);
            }
            &:active {
                background: var(--gray-06);
            }
            span {
                font-size: calc(1.4rem * var(--global-text-size, 1));
                color: var(--gray-02);
                user-select: none;
            }
        }
        .quantity-total {
            height: 3rem;
            min-width: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            > p {
                font-size: calc(1.6rem * var(--global-text-size, 1));
                font-weight: 500;
                color: var(--gray-06);
            }
        }
    }
}