@import "../../../mixing.scss";

.d-s-display-container {
    .d-s-display-inner-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 2rem;
        .d-s-display-cell {
            width: 250px;
            border-radius: 3px;
            overflow: hidden;
            position: relative;
        }
    }
}
  