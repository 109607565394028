@import "../../../mixing.scss";

body.mobile {
    button.c-item-can-container {
        margin-inline-start: 0;
        position: absolute;
        inset-inline-end: 1rem;
    }    
}

button.c-item-can-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-inline-start: 4rem;
    cursor: pointer;
    background: none;
    border: none;
    @include desktopHover() {
        > span {
            color: var(--gray-04);
        }        
    }
    &:active {
        > span {
            color: var(--gray-06);
        }
    }
    > span {
        color: var(--gray-06);
        transition: .1s ease-in-out;
        font-size: calc(2.4rem * var(--global-text-size, 1));
    }
}