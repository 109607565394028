@import "../../../../../../mixing.scss";

body.mobile {
    .p-option-values-container {
        min-width: 70%;
        max-width: 70%;
        overflow-x: scroll;
        flex-wrap: nowrap;
        &::-webkit-scrollbar-thumb {
            background: var(--gray-03);
            border-radius: 4px;
            transition: .1s ease-in-out;
            @include desktopHover() {
                background: var(--gray-06);
            }
        }
        &::-webkit-scrollbar {
            height: 4px;
        }
    }
}

.p-option-values-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: .7rem;
    padding: .5rem;
}