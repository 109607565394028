@import "../../../../../mixing.scss";

body[dir="rtl"] {
    .p-add-container {
        &.active {
            > p {
                > span {
                    margin-inline-start: unset;
                    margin-inline-end: 1rem;
                    transform: scaleX(-1);
                } 
            }
        }
    }    
}

.p-add-container {
    display: none;
    position: absolute;
    inset: 0;
    transition: .1s ease-in-out;
    &.active {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--gray-08);
        > p {
            color: var(--gray-02);
            display: flex;
            align-items: center;
            > span {
                margin-inline-start: 1rem;
                font-size: calc(2.4rem * var(--global-text-size, 1));
            } 
        }
        @include desktopHover() {
            background: var(--gray-06);;
        }
        &:active {
            background: var(--gray-08);
        }
    }
}