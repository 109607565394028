body[dir="rtl"] {
    .p-preview-title-container {
        > p {
            > span {
                margin: 0 1rem 0 0;
                &.flip-on-rtl {
                    transform: scaleX(-1);
                }
            }
        }
    }
}

body.mobile {
    .p-preview-title-container {
        > p {
            font-size: calc(1.6rem * var(--global-text-size, 1));
        }
    }    
}

.p-preview-title-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block-start: auto;
    padding-inline: 1rem;
    > p {
        font-size: calc(1.6rem * var(--global-text-size, 1));
        transition: .1s ease-in-out;
        color: var(--gray-04);
        font-weight: 500;
        text-transform: capitalize;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        > span {
            margin: 0 0 0 1rem;
            font-size: calc(2.4rem * var(--global-text-size, 1));
            &.flip-on-rtl {
                transition: transform .4s ease;
            }
        }
    }
}