body.mobile {
    .e-form-t-input-container {
        textarea {
            min-height: 50vh;
            resize: vertical;
        }
    }
}

.e-form-t-input-container {
    min-height: 100%;
    width: 100%;
    position: relative;
    textarea {
        font-family: 'Poppins', sans-serif;
        width: 100%;
        height: auto;
        min-height: 100%;
        font-size: calc(1.6rem * var(--global-text-size, 1));
        border: 2px solid transparent;
        padding: 1rem;
        background: var(--gray-01);
        border-radius: 3px;
        resize: none;
        transition: .2s ease-in-out;
        opacity: 1;
        &.dir-rtl {
            direction: rtl;
        }
        &.dir-ltr {
            direction: ltr;
        }
        &:focus {
            outline: none;
            border-color: var(--gray-06);
        }
        &::placeholder {
            transition: .2s ease-in-out;
            color: var(--gray-04);
        }
        &.error {
            border-color: var(--gray-04);
            &::placeholder {
                color: var(--blue-01);
            }
        }
    }
}