body.mobile {
    .e-form-name-container {
        input.name {
            padding: 1rem 1.5rem;
            font-size: calc(1.8rem * var(--global-text-size, 1));
            height: 5rem;
        }
    }        
}

.e-form-name-container {
    width: 100%;
    input.name {
        font-family: 'Poppins', sans-serif;
        width: 100%;
        margin-inline-end: auto;
        font-size: calc(1.6rem * var(--global-text-size, 1));
        border: none;
        padding: 1rem;
        color: var(--gray-06);
        background: var(--transparent);
        border-block-end: 1px solid var(--gray-04);
        transition: .2s ease-in-out;
        opacity: 1;
        &:focus {
            outline: none;
        }
        &::placeholder {
            transition: .2s ease-in-out;
            color: var(--gray-04);
        }
        &.error {
            border-block-end-color: var(--gray-06);
            &::placeholder {
                color: var(--blue-01);
            }
        }
    }
}