body.mobile {
    .p-price-container {
        margin-block-end: auto;
        margin-inline-start: auto;
        > h2 {
            font-size: calc(2.2rem * var(--global-text-size, 1));
        }
    }    
}

.p-price-container {
    display: flex;
    flex-wrap: wrap;
    > h2 {
        color: var(--gray-06);
    }
}