.d-s-filters-container {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: .2s ease-in-out;
    min-height: inherit;
    padding-inline: 2rem;
    padding-block-end: 4rem;
    > p {
        margin-block-start: 2rem;
        color: var(--gray-04);
        user-select: none;
        > span {
            text-decoration: underline;
        }
    }
}