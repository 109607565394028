body.mobile {
    .c-message-counter-container {
        inset-inline-end: 1rem;
        > p {
            font-size: calc(1.2rem * var(--global-text-size, 1));
        }
    }
}

.c-message-counter-container {
    height: 2rem;
    position: absolute;
    inset-inline-end: 2rem;
    inset-block-start: 100%;
    padding-inline: 1rem;
    inset-block-end: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 0;
    transition: .2s ease-in-out;
    &.error {
        opacity: 1;
        > p {
            color: var(--blue-01);
        }
    }
    &.visible {
        opacity: 1;
    }
    > p {
        margin: 0;
        font-size: calc(1.2rem * var(--global-text-size, 1));
        color: var(--gray-04);
        transition: .2s ease-in-out;
    }
}