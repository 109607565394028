body.mobile {
    .faq-search-container {
        margin-block-start: 12rem;
        padding-inline: 5%;
        input {
            font-size: calc(1.8rem * var(--global-text-size, 1));
            height: 5rem;
        }
    }        
}

.faq-search-container {
    width: 100%;
    max-width: 50rem;
    margin-block-start: 5rem;
    input {
        font-family: 'Poppins', sans-serif;
        width: 100%;
        margin-inline-end: auto;
        font-size: calc(1.6rem * var(--global-text-size, 1));
        border: none;
        padding: 1rem 2rem;
        height: 5rem;
        border-radius: 2.5rem;
        color: var(--gray-02);
        background: var(--gray-08);
        transition: .2s ease-in-out;
        &:focus {
            outline: none;
        }
        &::placeholder {
            transition: .2s ease-in-out;
            color: var(--gray-02);
        }
    }
}