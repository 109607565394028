@import "../../../../../mixing.scss";

.p-loading-container {
    display: none;
    position: absolute;
    inset: 0;
    &.active {
        position: absolute;
        z-index: 1;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--gray-02); 
        &:before {
          content: "";
          border: 5px solid var(--gray-03);
          border-top: 5px solid var(--gray-06);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          animation: spin 1s linear infinite;
        }
    }  
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}