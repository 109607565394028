footer.desktop {
    margin-block-start: auto;
    width: 100%;
    background: var(--gray-08);
    padding-inline: 5rem;
    display: flex;
    justify-content: center;
    .inner-container {
        height: inherit;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1600px;
    }
}