button.m-faq-selected-cat-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    background: unset;
    &.open {
        .arrow-container {
            > span {
                transform: rotate(180deg);
            }
        }
    }
    > p {
        user-select: none;
        color: var(--gray-08);
        margin-inline-start: 1.5rem;
        font-weight: 500;
        transition: .1s ease-in-out;
        text-transform: capitalize;
    }
    .arrow-container {
        width: 4rem;
        height: 100%;
        margin-inline-start: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
            font-size: calc(3rem * var(--global-text-size, 1));
            color: var(--gray-08);
            transition: .2s ease-in-out;
        }
    }
}