.p-share-popup-container {
    position: fixed;
    inset-inline: 0;
    max-width: 100%;
    width: fit-content;
    inset-block-end: 0;
    height: 0;
    min-height: 0;
    background: var(--gray-01);
    z-index: 10000;
    border-radius: 4rem 4rem 0 0;
    overflow: hidden;
    padding-inline: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .2s ease-in-out;
    margin-inline: auto;
    &.open {
        min-height: 20rem;
    }
}
.p-share-popup-cover {
    position: fixed;
    z-index: 1000;
    inset-inline: 0;
    inset-block-end: 0;
    height: 0;
    background: var(--gray-08-opacity);
    opacity: 0;
    transition: opacity .2s;
    &.open {
        height: 100%;
        opacity: 1;
    }
}