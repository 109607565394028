.option-value-container {
    display: flex;
    align-items: center;
    margin-block-start: 1rem;
    padding-inline-start: 1rem;
    cursor: pointer;
    user-select: none;
    &.lock {
        pointer-events: none;
        > p {
            color: var(--gray-03);    
        }
    }
    &.one-value {
        pointer-events: none;
        > p {
            color: var(--gray-03);    
        }
    }
    > p {
        margin-inline-start: 1rem;
        color: var(--gray-06);
        font-weight: 500;
        transition: .1s ease-in-out;
        text-transform: capitalize;
    }
}