body.mobile {
    .p-desctiption-container {
        padding-inline: 5%;
        margin-block-start: 1rem;
        > p {
            font-size: calc(1.6rem * var(--global-text-size, 1));
        }
    }    
}

.p-desctiption-container {
    width: 100%;
    max-width: 600px;
    margin-block-start: 2rem;
    margin-block-end: 1rem;
    > p, span {
        font-size: calc(1.6rem * var(--global-text-size, 1));
        line-height: 1.3;
        color: var(--gray-06);
        font-family: 'Poppins', sans-serif;
        &#addGripClick {
            cursor: pointer;
        }
        &.hide {
            display: none;
        }
    }
}