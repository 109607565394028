@import "../../../mixing.scss";

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

body.mobile {
    .c-item-quantity-container {
        .c-item-quantity-inner-container {
            button.quantity-button {
                // width: 2rem;
                // height: 2rem;
                width: calc(2rem * var(--global-text-size, 1));
                height: calc(2rem * var(--global-text-size, 1));
                span {
                    font-size: calc(1rem * var(--global-text-size, 1));
                }
            }
            .quantity-total {
                height: fit-content;
                min-width: 4rem;
                > p {
                    font-size: calc(1.6rem * var(--global-text-size, 1));
                    font-weight: 500;
                }
            }
        }
    }
}

.c-item-quantity-container {
    min-height: 100%;
    display: flex;
    align-items: center;
    &.loading {
        .c-item-quantity-inner-container {
            button.quantity-button {
                cursor: default;
                background: var(--gray-06);
                @include desktopHover() {
                    background: var(--gray-06);
                }
                &.active {
                    background: var(--gray-04);
                    @include desktopHover() {
                        background: var(--gray-04);
                    }
                    span {
                        animation: spin 1s linear infinite;
                    }
                }
            }
        }
    }
    .c-item-quantity-inner-container {
        display: flex;
        align-items: center;
        button.quantity-button {
            border: none;
            width: calc(2.4rem * var(--global-text-size, 1));
            height: calc(2.4rem * var(--global-text-size, 1));
            background: var(--gray-06);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 50%;
            transition: .1s ease-in-out;
            padding: 0;
            @include desktopHover() {
                background: var(--gray-05);
            }
            &:active {
                background: var(--gray-06);
            }
            span {
                font-size: calc(1.4rem * var(--global-text-size, 1));
                color: var(--gray-02);
                user-select: none;
            }
        }
        .quantity-total {
            height: 3rem;
            min-width: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            > p {
                transition: .1s ease-in-out;
                font-size: calc(1.6rem * var(--global-text-size, 1));
                font-weight: 500;
                color: var(--gray-06);
            }
        }
    }
}