body.mobile {
    .c-item-price-container {
        min-width: unset;
        justify-content: flex-start;
        margin-inline-start: auto;
        min-width: fit-content;
        > p {
            font-weight: 400;
            font-size: calc(1.4rem * var(--global-text-size, 1));
        }
    }    
}

.c-item-price-container {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    > p {
        color: var(--gray-06);
        font-weight: 500;
    }
}