body.mobile {
    .product-option-container {
        margin-block-start: 4rem;
    }    
}

.product-option-container {
    width: 100%;
    margin-block-start: 2rem;
    &.dropdown-open {
        display: none;
    }
}