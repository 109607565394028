body.mobile {
    .contact-form-container {
        padding-inline: 5%;
        form.contact-form {
            min-height: calc(100vh - 15rem);
        }
    }        
}
.contact-form-container {
    width: 100%;
    padding-inline: 5rem;
    display: flex;
    justify-content: center;
    form.contact-form {
        width: 100%;
        max-width: 80rem;
        min-height: calc(100vh - 20rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
}