.dialog-container {
    position: fixed;
    inset: 0;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
        animation: fadeIn .2s ease-in;
    }
}