.d-terms-header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-block-end: 1px solid var(--gray-03);
    padding-block-end: 2rem;
    background: var(--gray-02);
    > h3 {
        color: var(--gray-06);
    }
}