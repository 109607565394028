body[dir="rtl"] {
    &.mobile {
        .mobile-footer-bottom-container {
            > p {
                > span.material-symbols-outlined {
                    margin-inline: 0 1rem;
                }
                &:nth-child(3) {
                    direction: ltr;
                }
            }
        }
    }
}

body.mobile {
    .mobile-footer-bottom-container {
        > p {
            font-size: calc(1.3rem * var(--global-text-size, 1));
        }
    }    
}

.mobile-footer-bottom-container {
    width: 100%;
    margin-block-start: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    > p {
        text-align: center;
        display: flex;
        align-items: center;
        margin-block-end: 2rem;
        font-size: calc(1.3rem * var(--global-text-size, 1));
        color: var(--gray-04);
        > span.material-symbols-outlined {
            margin-inline: 1rem 0;
            font-size: calc(2rem * var(--global-text-size, 1));
        }
    }
}