.p-n-email-container {
    width: 100%;
    padding-inline: 1rem;
    direction: ltr;
    input.email {
        font-family: 'Poppins', sans-serif;
        width: 100%;
        height: 5rem;
        margin-block-start: 2rem;
        margin-inline-end: auto;
        font-size: calc(1.6rem * var(--global-text-size, 1));
        border: none;
        padding: 1rem;
        background-color: var(--gray-01);
        border-radius: 5px;
        outline: 2px solid var(--gray-04);
        transition: .2s ease-in-out;
        opacity: 1;
        &:focus {
            outline-color: var(--gray-06);
        }
        &::placeholder {
            transition: .2s ease-in-out;
            color: var(--gray-04);
        }
        &.error {
            outline-color: var(--gray-04);
            &::placeholder {
                color: var(--blue-01);
            }
        }
    }
}