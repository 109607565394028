header.mobile {
    position: fixed;
    inset-block-start: 0;
    height: 5rem;
    padding-inline: 5%;
    &.scroll-up {
        inset-block-start: 0;
    }
    &.scroll-down {
        inset-block-start: -5rem;
    }
    .inner-container {
        height: inherit;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
    }
}