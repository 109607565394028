.e-form-text-dir-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding-block: 1rem;
    position: relative;
    > p {
        font-size: calc(1.9rem * var(--global-text-size, 1));
        color: var(--gray-06);
    }
}