.desktop-footer-bottom-container {
    min-width: 100%;
    display: flex;
    justify-content: center;
    p {
        direction: ltr;
        padding-block: 2rem;
        text-align: center;
        color: var(--gray-04);
    }
}