body.mobile {
    .p-option-name-container {
        min-width: 30%;
        max-width: 30%;
        > p {
            font-size: calc(1.6rem * var(--global-text-size, 1));
            font-weight: 500;
        }
    }    
}

.p-option-name-container {
    min-width: 10rem;
    max-width: 100%;
    margin-block-end: .5rem;
    > p {
        white-space: wrap;
        color: var(--gray-05);
        font-weight: 400;
    }
}