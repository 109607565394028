.exchange-error-message-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > h2 {
        margin-block-end: 2rem;
        text-align: center;
    }
    > p {
        text-align: center;
    }
}