body.mobile {
    .p-option-container {
        flex-direction: row;
        align-items: center;
        margin-block-end: 0;
    }
}

.p-option-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-block-start: 1rem;
}