@import "../../../../mixing.scss";

body[dir="rtl"] {
    a.d-faq-category-container {
        direction: rtl;
    }
}

a.d-faq-category-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    gap: 1rem;
    height: 5rem;
    flex: 1;
    transition: .1s ease-in-out;
    color: var(--gray-04);
    font-weight: 500;
    text-align: center;
    text-transform: capitalize;
    transition: .1s ease-in-out;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
    border-block-end: 2px solid var(--transparent);
    direction: ltr;
    @include desktopHover() {
        color: var(--gray-06);
    }
    &.selected {
        color: var(--gray-06);
        border-block-end-color: var(--gray-06);
    }
}