body.mobile {
    .e-form-title-container {
        margin-block-start: 4rem;
    }
}

.e-form-title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
        margin: 0;
    }
}