@mixin respond($breakpoint) {
	@media only screen and (max-width: ($breakpoint + 'px')) {
		@content;
	}
}

@mixin desktopHover() {
	@media (pointer: fine) {
		&:hover {
			@content;
		}
	}
}