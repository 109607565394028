body.mobile {
    .p-options-container {
        padding-inline: 5%;
        margin-block-start: 0;
    }    
}

.p-options-container {
    width: 100%;
    margin-block-start: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}