.p-n-message-bottom-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block-start: 2rem;
    > p {
        color: var(--gray-06);
        text-align: center;
        padding-inline: 1rem;
    }
}