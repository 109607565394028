@import "../../../../../app//mixing.scss";

body[dir="rtl"] {
    .desktop-footer-top-right-container {
        > p {
            span.material-symbols-outlined {
                margin-inline: 0 1rem;
            }
        }
    }
}

.desktop-footer-top-right-container {
    margin-inline: auto 0;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    user-select: none;
    > p {
        display: flex;
        align-items: center;
        color: var(--gray-04);
        margin-block-end: 2rem;

        &:nth-child(2) {
            color: var(--gray-04);
            user-select: text;
        }
        span.material-symbols-outlined {
            margin-inline: 1rem 0;
            font-size: calc(2rem * var(--global-text-size, 1));
            transition: transform .4s ease-in-out, color 4s ease-in-out;
        }
        @include desktopHover() {
            span.material-symbols-outlined {
                color: var(--blue-01);
                animation: heartbeat 1s infinite;
            }
        }
    }
}