body.mobile {
    .contact-message-container {
        height: unset;
        min-height: unset;
        textarea.message {
            resize: vertical;
            min-height: calc(100vh - 39rem);
        }        
    }
}

.contact-message-container {
    width: 100%;
    position: relative;
    height: calc(100vh - 42rem);
    textarea.message {
        font-family: 'Poppins', sans-serif;
        width: 100%;
        height: 100%;
        font-size: calc(1.6rem * var(--global-text-size, 1));
        border: none;
        border: 2px solid transparent;
        padding-inline: 1rem 5rem;
        padding-block: 1rem;
        background: var(--gray-01);
        border-radius: 3px;
        resize: none;
        transition: .2s ease-in-out;
        opacity: 1;
        &:focus {
            outline: none;
            border-color: var(--gray-06);
        }
        &::placeholder {
            transition: .2s ease-in-out;
            color: var(--gray-04);
        }
        &.error {
            border-color: var(--gray-04);
            &::placeholder {
                color: var(--blue-01);
            }
        }
    }
}