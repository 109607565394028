.d-p-data-container {
    width: 100%;
    min-height: calc(100vh - 30rem);
    // min-width: 450px;
    display: flex;
    flex-direction: column;
    padding-block-start: 10rem;
    .d-p-data-inner-container {
        display: flex;
        gap: 5rem;
        margin-block-end: auto;
        height: 100%;
        .d-p-info-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: calc(100% - 250px);
        }
        .d-p-sum-container {
            min-width: 200px;
            max-width: 200px;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}