.value-chack-box {
    width: 2rem;
    height: 2rem;
    border-radius: 3px;
    border: 2px solid var(--gray-06);
    display: flex;
    align-items: center;
    justify-content: center;
    &.lock {
        border-color: var(--gray-03);
    }
    &.one-value {
        border-color: var(--gray-03);
        &:before {
            background: var(--gray-03);
        }
    }
    &:before {
        content: "";
        width: .7rem;
        height: .7rem;
        border-radius: 50%;
        position: absolute;
        background: var(--transparent);
        transition: .1s ease-in-out;
    }
    &.active {
        &:before {
            background: var(--gray-06);
        }
    }
}