section.d-terms {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 27rem);
    .d-terms-inner-container {
        ol {
            margin-block-start: 1rem;
            li {
                margin-block-start: 1rem;
            }
        }
    }
}