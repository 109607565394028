@import "../../../../../mixing.scss";

body[dir="rtl"] {
    button.text-dir-switcher-container {
        .text-dir-switcher-inner-container {
            flex-direction: row-reverse;
        }
    }    
}

button.text-dir-switcher-container {
    height: 100%;
    margin-inline-start: auto;
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    background: none;
    .text-dir-switcher-inner-container {
        position: relative;
        inset-inline-end: 0;
        border-radius: 3px;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        height: 4rem;
        overflow: hidden;
        > span {
            background: var(--gray-03);
            height: calc(100% - .5rem);
            aspect-ratio: 1 / 1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--gray-05);
            transition: .1s ease-in-out;
            font-size: calc(2rem * var(--global-text-size, 1));
            padding: .5rem;
            height: 3.5rem;
            cursor: pointer;
            &.dir-rtl {
                border-radius: 0 3px 3px 0;
            }
            &.dir-ltr {
                border-radius: 3px 0 0 3px;
            }
            &.active {
                height: 4rem;
                border-radius: 3px;
                background: var(--gray-06);
                color: var(--gray-02);
                font-size: calc(2.4rem * var(--global-text-size, 1));
                @include desktopHover() {
                    background: var(--gray-06);
                    color: var(--gray-02);
                }
            }
            @include desktopHover() {
                color: var(--gray-08);
            }
        }
    }
}