@import "../../../../mixing.scss";

body.mobile {
    .e-form-i-agree-container {
        margin-block: 2rem;
        > .e-form-i-agree-input-container {
            margin-block-start: 4rem;
        }
    }    
}

.e-form-i-agree-container {
    width: 100%;
    > p {
        color: var(--gray-06);
        font-size: calc(1.6rem * var(--global-text-size, 1));
        word-break: keep-all;
        > a {
            color: var(--gray-06);
            transition: .1s ease-in-out;
            @include desktopHover() {
                color: var(--gray-05);
            }
            &:active {
                color: var(--gray-06);
            }
        }
    }
    > button.e-form-i-agree-input-container {
        background: none;
        border: none;
        margin-block-start: 2rem;
        display: flex;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        user-select: none;
        @include desktopHover() {
            > .i-agree-squere {
                border-color: var(--gray-08);
            }
            > p {
                color: var(--gray-06);
            }
            &.error {
                > .i-agree-squere {
                    border-color: var(--gray-08);        
                }
                > p {
                    color: var(--gray-06);
                }
            }
        }
        &.active {
            > .i-agree-squere {
                border-color: var(--gray-08);
                > span {
                    color: var(--gray-08);
                }
            }
            > p {
                color: var(--gray-06);
            }
        }
        > .i-agree-squere {
            width: calc(1.8rem * var(--global-text-size, 1));
            height: calc(1.8rem * var(--global-text-size, 1));
            background: var(--gray-02);
            margin-inline-end: 1rem;
            border: 1px solid var(--gray-04);
            border-radius: 2px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .1s ease-in-out;
            > span {
                color: var(--transparent);
                font-size: calc(2rem * var(--global-text-size, 1));
                transition: .1s ease-in-out;
            }
        }
        > p {
            color: var(--gray-04);
            line-height: 1;
            transition: .1s ease-in-out;
        }
        &.error {
            > .i-agree-squere {
                border-color: var(--gray-06);        
            }
            > p {
                color: var(--blue-01);
            }
        }
    }
}