@import "../../../../app/mixing";

body[dir="rtl"] {
    nav.desktop {
        ul {
            li {
                a {
                    > span {
                        margin-inline-end: unset;
                        margin-inline-start: 1rem;
                        &.flip-on-rtl {
                            transform: scaleX(-1);
                        }
                    }
                }
               
            }
        }
    }
}

nav.desktop {
    margin-inline: calc(2rem + 1vw);
    ul {
        display: flex;
        gap: calc(2rem + 1vw);
        list-style: none;
        padding: 0;
        flex-direction: row;
        li {
            display: flex;
            align-items: center;
            position: relative;
            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                font-size: calc(1.8rem * var(--global-text-size, 1));
                color: var(--gray-04);
                transition: .2s ease-in-out;
                line-height: 1;
                position: relative;
                @include desktopHover() {
                    color: var(--gray-02);
                    span {
                        color: var(--gray-02);
                    }
                }
                > span {
                    margin-inline-end: 1rem;
                    color: var(--gray-04);
                    line-height: 1;
                    font-size: calc(2.4rem * var(--global-text-size, 1));
                    transition: .2s ease-in-out, transform  .4s ease;
                }
            }
            &.active {
                a {
                    color: var(--gray-02);
                    > span {
                        color: var(--gray-02);
                    }
                }
            }
        }
    }
}