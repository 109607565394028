@import "../mixing.scss";

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding-block-start: 10rem;
    min-height: 100vh;
    width: 100%;
    h1 {
        font-size: 20rem;
        color: var(--gray-02);
        margin: 0;
        line-height: 1;
        font-weight: 600;
    }
    p {
        font-size: 2rem;
        color: var(--gray-04);
        margin: 0;
        line-height: 1;
    }
    button {
        background: transparent;
        color: var(--gray-04);
        border: none;
        font-size: 2rem;
        transition: .1s ease-in-out;
        cursor: pointer;
        @include desktopHover {
            color: var(--gray-02);
            text-decoration: underline;
        }
    }
}