.d-p-gallery-container {
    // width: 100%;
    // max-width: 500px;
    max-width: 40vw;
    .d-p-sticky-container {
        // width: 100%;
        position: sticky;
        inset-block-start: 0;
        display: flex;
        flex-direction: column;
        .d-p-gallery-top-container {
            // width: 100%;
            height: 10rem;
            display: flex;
            align-items: center;
        }
        .d-p-gallery-inner-container {
            position: relative;
            width: 100%;
            // max-width: 500px;
            height: calc(100vh - 30rem);
            display: flex;
            gap: 1rem;
        }
    }
}