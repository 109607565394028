.d-c-item-container {
    min-height: 100%;
    width: 100%;
    border-block-end: 1px solid var(--gray-02);
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-block: 1rem;
    .c-item-quantity-and-price-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        justify-content: center;
        flex-wrap: wrap;
        width: 50%;
    }
}