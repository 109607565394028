.d-s-menu-container {
    min-height: calc(100vh - 25rem);
    position: sticky;
    inset-block-start: 15rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    .d-s-menu-inner-container {
        width: 100%;
        min-height: calc(100vh - 20rem);
        background: var(--gray-01);
        transition: .1s ease-in-out;
        border-radius: 0 3px 0 0;
        overflow: hidden;
        &.categories {
            background: var(--gray-01);
        }
        &.filters {
            background: var(--gray-01);
        }
        .d-s-categories-container {
            width: 100%;
            min-height: 100%;
        }
        .d-s-all-filters-container {
            width: 100%;
            min-height: 100%;
            min-height: calc(100vh - 25rem);
        }
    }
}