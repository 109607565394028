.c-s-s-c-c-top-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-inline-start: 1rem;
    direction: ltr;
    cursor: pointer;
    &.open {
        > span {
            transform: rotate(180deg);
        }
    }
    > p {
        width: 100%;
        font-size: calc(1.6rem * var(--global-text-size, 1));
        color: var(--gray-04);
    }
    > span {
        margin-inline-start: auto;
        font-size: calc(2.4rem * var(--global-text-size, 1));
        color: var(--gray-06);
        transform: rotate(0deg);
        transition: .3s ease-in-out;
    }
}